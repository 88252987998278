<template>
  <div class="session-explorer">
    <q-bar-input
      :config="qbarConfig" :filters="qbarFilters"
        @update="qbarUpdateFilters"
        @commit="qbarCommitFilters"
      class="shadow" />
    <div class="block">
      <table>
        <thead>
          <tr>
            <th>Heure</th>
            <th>Url</th>
            <th>Code HTTP</th>
            <th>Erreur</th>
            <th>Temps de réponse</th>
            <th width="70">Bytes</th>
            <th width="150">Appareil</th>
          </tr>
        </thead>
        <tbody v-if="!exploreResults">
          <tr>
            <td colspan="7" class="message">Ajoutez des filtres pour commencer</td>
          </tr>
        </tbody>
        <tbody v-else-if="exploreResults.length > 0">
          <tr v-for="r in exploreResults" :key="r.id" >
            <td :title="formatDateTime(r.date)">{{formatTime(r.date)}}</td>
            <td><a :href="r.url" target="_blank">{{r.path}}</a></td>
            <td>{{r.statusCode}}</td>
            <td><em class="explore-error" v-if="r.error">{{r.error}}</em></td>
            <td>{{r.duration}} ms</td>
            <td>{{formatBytes(r.bytes || 0)}}</td>
            <td><agent :agent="r.agent" :title="r.ua" /></td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr>
            <td colspan="7" class="message">
              Désolé, il n'y a qui rien ne correspond à votre recherche</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import formatters from '@/mixins/formatters';
import api from '@/lib/api';
import QBarInput from './qbar/QBarInput.vue';
import Agent from './Agent.vue';

export default {
  mixins: [formatters],
  components: {
    Agent,
    QBarInput,
  },
  props: {
    session: Object,
  },
  data() {
    return {
      exploreFilter: {},
      exploreResults: null,
      qbarFilters: [],
      qbarBaseConfig: [
        {
          label: 'Url',
          name: 'url',
          color: '#592e83',
          operators: [
            { value: 'like', label: 'contient' },
          ],
        },
        {
          label: 'Status',
          name: 'status',
          color: '#f6ae2d',
          values: [
            { value: 'error', label: 'Erreur' },
            { value: 'done', label: 'Succès' },
          ],
        },
        {
          label: 'Code HTTP',
          name: 'statusCode',
          color: '#1a936f',
          values: [
            { value: 200, label: '200 OK' },
            { value: 400, label: '400 Bad Request' },
            { value: 401, label: '401 Unauthorized' },
            { value: 403, label: '403 Forbidden' },
            { value: 404, label: '404 Not found' },
            { value: 408, label: '408 Request Time-out' },
            { value: 429, label: '429 Too Many Requests' },
            { value: 500, label: '500 Internal Server Error' },
            { value: 503, label: '503 Service Unavailable' },
            { value: 504, label: '504 Gateway Time-out' },
            { value: 521, label: '521 Web Server Is Down' },
          ],
        },
        {
          label: 'Temps de réponse (ms)',
          name: 'duration',
          type: 'number',
          color: '#468c98',
          operators: [
            { value: 'gte', label: '≥' },
            { value: 'lte', label: '≤' },
          ],
        },
      ],
    };
  },
  computed: {
    qbarConfig() {
      const cfg = [...this.qbarBaseConfig];
      if (this.session && this.session.settings.agents.length > 1) {
        cfg.push({
          label: 'Appareil',
          name: 'agent',
          color: '#00B2CA',
          values: this.session.settings.agents.map((a) => ({
            value: a,
            label: a,
          })),
        });
      }
      return cfg;
    },
  },
  methods: {
    qbarUpdateFilters(filters) {
      this.qbarFilters = filters;
    },
    qbarCommitFilters(filters) {
      this.qbarFilters = filters;
      this.explore();
    },
    async explore() {
      console.log(this.qbarFilters);
      // Serialize
      const filters = this.qbarFilters
        .reduce((p, f) => {
          if (!p[f.name]) {
            // eslint-disable-next-line no-param-reassign
            p[f.name] = [];
          }
          if (!f.operator) {
            // eslint-disable-next-line no-param-reassign
            p[f.name][0] = p[f.name][0] || ['in'];
            p[f.name][0].push(f.value);
          } else {
            p[f.name].push([f.operator, f.value]);
          }
          return p;
        }, {});
      // const serializedParams = Object.keys(params).reduce((p, k) => ({
      //   ...p,
      //   [k]: JSON.stringify(params[k]),
      // }), {});

      // console.log(serializedParams);
      const res = await api.get(`/sessions/${this.session.id}/explore`, {
        queryStringParameters: {
          filters: JSON.stringify(filters),
        },
      });
      this.exploreResults = res.map((r) => ({
        ...r,
        path: new URL(r.url).pathname,
      }));
    },
  },
};
</script>
<style lang="scss" scoped>
.explore-error {
  display: block;
  opacity: .7;
  color: #e00;
}
table .message {
  text-align: center;
  font-style: italic;
  padding: 2em;
  opacity: .5;
}
</style>
