<template>
  <span class="q-bar-filter"
    :style="{'background-color':value.color}">
    <span class="q-bar-filter-label">{{ label }}</span>

    <span class="q-bar-filter-operator" v-if="operators.length > 1">
      <select @input="selectOperator($event.target.value)">
        <option
          v-for="op in operators" :key="op.value"
          :selected="op.value === value.operator"
          :value="op.value">{{op.label}}</option>
      </select>
      <span>{{operatorLabel || '...'}}</span>
    </span>

    <span class="q-bar-filter-value">
      <input v-if="!values.length"
        :type="value.type" :value="value.value"
        ref="input"
        @input="setValue($event.target.value)"
        @keypress.enter="$emit('commit')"
        />
      <select
        @input="setValue($event.target.value, true)"
        ref="select"
        v-if="values.length">
        <option
          v-for="v in values" :key="v.value"
          :selected="v.value === value.value"
          :value="v.value">{{v.label || v.value}}</option>
      </select>
      <span v-if="values.length">{{valueLabel || '...'}}</span>
    </span>

    <span class="q-bar-delete">
      <a @click.prevent="$emit('remove')" class="btn-delete">&times;</a>
    </span>
  </span>

</template>
<script>
export default {
  props: {
    value: null,
  },
  components: {},
  methods: {
    focus() {
      if (this.values.length) {
        this.$refs.select.focus();
        this.$refs.select.click();
      } else {
        this.$refs.input.focus();
      }
    },
    selectOperator(operator) {
      this.change({ operator });
      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    setValue(value, done) {
      let v = value;
      if (this.value.type === 'number') {
        v = +value;
      }
      this.change({ value: v });
      if (done) {
        this.$emit('done');
      }
    },
    change(modifier) {
      this.$emit('change', { ...this.value, ...modifier });
    },
  },
  computed: {
    label() {
      return this.value.label;
    },

    operators() {
      if (this.value.operators instanceof Array) {
        return this.value.operators;
      }
      switch (this.value.type) {
        case 'number':
          return [
            { value: 'gt', label: '>' },
            { value: 'gte', label: '≥' },
            { value: 'lt', label: '<' },
            { value: 'lte', label: '≤' },
            { value: 'eq', label: '=' },
            { value: 'ne', label: '≠' },
          ];
        default:
          return [];
      }
    },
    operatorLabel() {
      const op = this.operators.find((v) => v.value === this.value.operator);
      return op ? op.label : '-';
    },
    valueLabel() {
      const val = this.values.find((v) => v.value === this.value.value);
      return val ? val.label || val.value : '-';
    },
    values() {
      return this.value.values || [];
    },
  },
  data() {
    return {

    };
  },
  mounted() {
    // this.updateInputSize();
  },
};
</script>
<style lang="scss" scoped>

$border-radius: 10px;
$danger: red;

.q-bar-filter {
  font-size: 1em;
  display: inline-flex;
  position: relative;
  align-items: center;
  margin-right: 0.4em;
  margin-bottom: 0.4em;
  padding: .2em .2em;
  background: #fefefe;
  border-radius: $border-radius;
  // border: 2px solid;
  color: #fff;
  box-shadow: 0 1px 2px rgba(black, .3);

  // label {
  //   font-weight: normal;
  //   text-transform: uppercase;
  //   font-size: 0.7em;
  //   position: relative;
  //   padding-right: 0.5em;
  //   opacity: 0.6;
  //   &:after {
  //     content: "";
  //     display: block;
  //     position: absolute;
  //     right: 0;
  //     top: -20%;
  //     height: 130%;
  //     border-right: 1px solid rgba(0, 0, 0, 0.2);
  //   }
  // }

}
.q-bar-filter-label {
  font-weight: 500;
  padding: 0 .3em;
}

.q-bar-filter-label, .q-bar-filter-operator {
  // border-right:1px solid rgba(0, 0, 0, 0.2);
}
.q-bar-filter-operator, .q-bar-filter-value {
  overflow: hidden;

  position: relative;
  min-width: 0.5em;

  cursor: pointer;
  &:hover, &:focus {
    outline: none;
    cursor: pointer;
  }
  select {
    opacity: 0;
    border: none;
    background-color: transparent;
    border-radius: 0;
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    width: 120%;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:hover {
      cursor: pointer;
    }
  }
  select:focus+span {
    text-decoration: underline;
    text-shadow: 0 0 20px #fff;
  }
  &:hover {
    background-color: rgba(white,.7);
  }
}

.q-bar-filter-operator {
  padding: 0 .5em;
  margin: 0 .2em;
  border-radius: 5px;
  border: 2px solid rgba(#fff, .2);
  background-color: rgba(white,.2);
  transition: ease .2s all;
  &:hover {
    background-color: rgba(white,.3);
    border-color: rgba(#fff, .7);
  }
}

.q-bar-filter-value {
  font-weight: 500;

  color:#333;
  padding: 0.1em .5em;
  background-color: rgba(white, .8);
  border-radius: 5px 8px 8px 5px;
  input {
    margin: 0;
    font-size: 1em;
    padding: 0;
    border:none;
    background: transparent;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }
  input[type=number] {
    width: 4em;
    text-align: right;
  }
}

.q-bar-delete {
  opacity: 0;
  transition: all ease .2s;
  z-index: 10;
  position:absolute;
  right: -.5em;
  top: -.3em;
  transform: scale(0.7);
  a {
    color: #777;
    text-decoration: none;
    cursor: pointer;
    display: inline-block;
    text-shadow: 0px 0px 4px rgba(#fff,.3);
    width: 1.2em;
    height: 1.2em;
    line-height: 1.2em;
    text-align: center;
    color: #fff;
    border-radius: 10px;
    background-color: #500;
    box-shadow: 0 3px 10px rgba(#000,.3);

    &:hover {
      background-color: #f00;
    }
  }
}
.q-bar-delete:hover {
  transform: scale(1);
}
.q-bar-filter:hover .q-bar-delete {
  top: -.5em;
  opacity: 1;
}
</style>
