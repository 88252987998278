<template>
  <trend
    v-if="true"
    :data="data"
    gradientDirection="top"
    :gradient="['#833ab4', '#fd1d1d', '#fcb045']"
    :padding="0"
    :radius="12"
    :width="200"
    :stroke-width="1"
    stroke-linecap="round"
    auto-draw
    smooth>
  </trend>
</template>
<script>

import trend from 'vuetrend';

export default {
  components: {
    trend,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
};
</script>
