<template>
  <div class="view-session" v-if="session && session.id">
    <div class="view-header">
      <h2>Session: <span>{{session.name}}</span></h2>
      <div>
        <button v-if="session.status === 'cooking'" @click="abort" class="stop">STOP</button>
        <button-reload @click="refreshSession(true)"></button-reload>
        <session-status :status="session.status" />
      </div>

    </div>
    <div class="progress-block">
      <ul class="progress">
        <li :class="progressCls('prepare')"><span>Préparation</span></li>
        <li :class="progressCls('ignition')"><span>Allumage</span></li>
        <li :class="progressCls('cooking')"><span>Préchauffage</span></li>
        <li :class="progressCls('done')"><span>Terminé</span></li>
      </ul>
    </div>
    <div class="block metrics">
      <div class="kpi" v-if="metrics.count>0">
        <label>Progression</label>
        <span>{{Math.round(100*(metrics.done + metrics.errors) / metrics.count)}} %</span>
        <session-sparkline :data="metricSerie('done')" />
        <em>{{metrics.done + metrics.errors}} / {{metrics.count}}</em>
      </div>
      <div class="kpi">
        <label>Durée</label>
        <span>{{duration}}</span>
      </div>
      <div class="kpi" v-if="metrics.totalBytes>0">
        <label>Bande passante</label>
        <span>{{formatBytes(metrics.totalBytes)}}</span>
        <session-sparkline :data="metricSerie('totalBytes')" />
      </div>
      <div class="kpi" v-if="metrics.errors>0">
        <label>Erreurs</label>
        <span>{{metrics.errors}}</span>
        <session-sparkline :data="metricSerie('errors')" />
      </div>
      <div class="kpi" v-if="metrics.durationAvg>0">
        <label>Temps de réponse</label>
        <span>{{Math.round(metrics.durationAvg)}} ms</span>
        <session-sparkline :data="metricSerie('durationAvg')" />
      </div>

    </div>
    <h3>Infos</h3>
    <div class="block">
      <dl>
        <dt>Sitemap</dt>
        <dd><a :href="session.sitemap" target="_blank">{{session.sitemap}}</a></dd>
        <dt>Déclencheur</dt>
        <dd>
          <session-trigger :trigger="session.trigger" />
        </dd>
        <dt>Appareils</dt>
        <dd>
          <agent v-for="a in session.settings.agents" :key="a" :agent="a" />
        </dd>
        <dt>Courbe de chauffe</dt>
        <dd>
          {{session.settings.warmingCurveMode}}
        </dd>
        <dt>Economiseur de bande passante</dt>
        <dd>
          {{session.settings.bandwithSaverMode}}
        </dd>
        <dt>Sandbox</dt>
        <dd>
          {{session.settings.sandbox || 'non'}}
        </dd>
      </dl>
    </div>
    <h3>Explore</h3>
    <session-explorer v-if="session" :session="session" />
  </div>
</template>
<script>

import { mapActions, mapState } from 'vuex';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import formatters from '@/mixins/formatters';

import SessionSparkline from '../components/SessionSparkline.vue';
import SessionStatus from '../components/SessionStatus.vue';
import SessionTrigger from '../components/SessionTrigger.vue';
import Agent from '../components/Agent.vue';
import ButtonReload from '../components/ButtonReload.vue';

import SessionExplorer from '../components/SessionExplorer.vue';

dayjs.extend(duration);

export default {
  mixins: [formatters],
  components: {
    SessionSparkline,
    SessionStatus,
    SessionTrigger,
    Agent,
    ButtonReload,
    SessionExplorer,
  },

  computed: {
    ...mapState('sessions', [
      'session',
    ]),
    metrics() {
      if (this.session.metricsDetails && this.session.metricsDetails.count) {
        return this.session.metricsDetails; // XXX legacy
      }
      return this.session.metrics || {};
    },
    details() {
      return (this.session.metricsDetails || {}).details || [];
    },
    project() {
      return this.session.project || {};
    },
    duration() {
      const start = dayjs(this.session.date);
      const end = dayjs(this.session.terminatedAt || new Date());
      return dayjs.duration(end.diff(start)).format('HH:mm:ss');
    },
  },
  methods: {
    ...mapActions('sessions', [
      'loadSessions',
      'refreshSession',
      'abortSession',
      'useSession',
    ]),
    async onEnter({ id }) {
      await this.useSession(id);
    },

    progressCls(status) {
      const statuses = ['pending', 'preprare', 'ready', 'ignition', 'cooking', 'done'];
      const a = statuses.indexOf(this.session.lastStatus || this.session.status);
      const b = statuses.indexOf(status);
      if (a === b && b < (statuses.length - 1)) {
        return {
          active: true,
          abort: this.session.status === 'abort',
        };
      }
      if (a >= b) return 'done';
      return '';
    },
    metricSerie(metric) {
      return (this.details || []).map((m) => m[metric]);
    },
    async abort() {
      await this.abortSession(this.session.id);
      await this.refreshSession();
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => vm.onEnter(to.params));
  },
  beforeRouteUpdate(to, from, next) {
    this.onEnter(to.params);
    next();
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';

// #833ab4, #fd1d1d, #fcb045

h2 span {
  @include text-gradient(linear-gradient(135deg,#833ab4, #fd1d1d))
}
.stop {
  background: #fd1d1d;
  color:#fff;
}
.metrics {
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.progress-block {
  position: relative;
  padding: 2rem 0;
}
.progress {
  display: flex;
  counter-reset: step;
}
.progress li {
  list-style-type: none;
  width: 25%;
  font-size: 0.8rem;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progress li:before {
  width: 50px;
  height: 50px;
  font-size: 1rem;
  font-weight: 100;
  content: counter(step);
  counter-increment: step;
  line-height: 50px;
  border: 5px solid transparent;
  box-shadow: 0 3px 10px rgba(#000, .15);
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
  position: relative;
  z-index: 2;
}
.progress li:after {
    width: 100%;
    height: 5px;
    content: '';
    position: absolute;
    background-color: #ccc;
    top: 27px;
    left: -50%;
    z-index: 0;
}
.progress li:first-child:after {
    content: none;
}
.progress li.active, .progress li.done {
    color: green;
}

.progress li.active.abort {
    color: #777;
}

.progress li.done:before {
    background: #55b776;
    color: #fff;
    font-size: 1.3rem;
    content: '✓';
}
.progress li.active:before {
    border-color: #55b776;
}
.progress li.active.abort:before {
    background: #777;
    color: #fff;
    border-color: #777;
    content: '×';
}
.progress li.active:after, .progress li.done:after {
    background-color: #55b776;
}

</style>
