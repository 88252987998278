<template>
  <div class="q-bar-input"
    :class="{focus: focus, suggest: suggestions.length>0}"
    @click="onTopFocus">
    <q-bar-filter
      v-for="(tag, i) in filterList"
      :key="i"
      :value="tag"
      ref="tag"
      @remove="removeTag(i)"
      @change="onTagChange(i, $event)"
      @commit="onTagCommit(i)"
      @done="onTagDone"
      />
    <input ref="tagInput" name="tagInput"
        v-model="inputValue"
        @keydown="onKeyDown"
        @keyup="onKeyUp"
        @focus="onFocus"
        @blur="onBlur"
        autocomplete="off"
        type="text">
    <span class="tag tag-sizer" ref="tagSizer"></span>
    <span v-if="hasPlaceholder" class="placeholder">{{ placeholder }}</span>
    <div class="tag-suggestions"
        v-if="suggestions.length>0">
        <a
          v-for="(suggestion, i) in suggestions"
          :key="suggestion.id"
          :class="{suggestion:true, selected:i===selectedSuggestionIndex}"
          @click="selectSuggestion(suggestion)">
            <div :style="{'background-color': suggestion.color}">
              <span>{{ suggestion.label }}</span>
              <em v-if="suggestion.value">{{suggestion.valueLabel || suggestion.value}}</em>
            </div>
        </a>
    </div>
  </div>
</template>
<script>
import QBarFilter from './QBarFilter.vue';

export default {
  components: { QBarFilter },
  props: {
    filters: Array,
    config: Array,
    onSuggest: Function,
    placeholder: {
      type: String,
      default: 'Filtrer...',
    },
  },
  methods: {
    onKeyDown(e) {
      switch (e.keyCode) {
        case 13: // Enter
          console.log(this.selectedSuggestionIndex);
          if (this.selectedSuggestionIndex !== -1) {
            this.addTag(this.suggestions[this.selectedSuggestionIndex]);
            this.selectedSuggestionIndex = -1;
          } else if (this.inputValue) {
            this.addTag(this.inputValue);
          } else {
            this.commit();
          }
          this.resetInput();
          this.updateSuggestion();
          return false;
        case 8: // Back
          if (!this.inputValue && this.filterList.length) {
            this.removeTag(this.filterList.length - 1);
            this.updateInputSize(true);
            return false;
          }
          return true;
        case 9: // Tab
        case 39: // Right
        case 40: // Down
          this.selectedSuggestionIndex += 1;
          if (this.selectedSuggestionIndex >= this.suggestions.length) {
            this.selectedSuggestionIndex = 0;
          }
          if (!this.inputValue && !this.suggestions.length) {
            this.updateSuggestion(true);
          }
          return false;
        case 37: // Left
        case 38: // Up
          this.selectedSuggestionIndex -= 1;
          if (this.selectedSuggestionIndex < 0) {
            this.selectedSuggestionIndex = this.suggestions.length - 1;
          }
          // this.update();
          return false;
        case 27: // Esc
          this.clearSuggestions();
          break;
          // case 9: // Tab

        //   break;
        default:
          break;
      }
      return true;
    },
    onKeyUp(e) {
      if (e.keyCode === 40 || e.keyCode === 38
      || e.keyCode === 37 || e.keyCode === 39) {
        return true;
      }
      this.updateInputSize();
      this.updateSuggestion();
      return true;
    },

    // Focus management
    onTopFocus() {
      if (!this.$el.contains(document.activeElement)) {
        this.$refs.tagInput.focus();
      }
    },
    onFocus() {
      this.focus = true;
    },
    onBlur() {
      this.focus = false;
    },
    onTagChange(i, newValue) {
      this.filterList.splice(i, 1, newValue);
      this.tagsChanged();
    },
    onTagCommit() {
      this.$refs.tagInput.focus();
      this.commit();
    },
    onTagDone() {
      this.$refs.tagInput.focus();
    },
    // Tag management
    addTag(tag) {
      const v = tag;
      if (!v || typeof v !== 'object') {
        return;
      }
      if (tag.operators && tag.operators.length > 0) {
        v.operator = tag.operators[0].value;
      }
      if (v.type === 'number' && !v.operator) {
        v.operator = 'gte';
        v.value = 0;
      }

      const i = this.filterList.push(v);
      this.tagsChanged();
      if (!v.value) {
        setTimeout(() => {
          // set focus later
          const el = this.$refs.tag[i - 1];
          if (el) {
            el.focus();
          }
        });
      }
    },
    removeTag(i) {
      this.filterList.splice(i, 1);
      this.tagsChanged();
    },

    // Input

    resetInput() {
      this.inputValue = '';
      this.$refs.tagInput.style.width = '1px';
      this.updateInputSize(true);
    },

    updateInputSize(reset) {
      if (!reset && !this.inputValue) {
        return this.updateInputSize(true);
      }
      this.$refs.tagSizer.textContent = this.inputValue;
      let reqw = `${this.$refs.tagSizer.getBoundingClientRect().width + 5}px`;
      if (reset) {
        reqw = '1em';
      }

      this.$refs.tagInput.style.width = reqw;
      return true;
    },
    selectSuggestion(tag) {
      this.addTag(tag);
      this.resetInput();
      this.updateSuggestion();
    },
    // Suggestion
    updateSuggestion(force) {
      const fn = this.onSuggest || this.doUpdateSuggestion;
      if (!this.inputValue && !force) {
        this.suggestions = [];
        return;
      }
      fn(this.inputValue, (values) => {
        this.suggestions = values;
        this.selectedSuggestionIndex = values.length ? 0 : -1;
      });
    },

    doUpdateSuggestion(value, $done) {
      const sourceList = [];
      const key = (f) => `${f.name}:${f.operator}:${f.value}`;
      const existing = new Set(this.filters.map(key));

      const push = (f) => {
        if (!existing.has(key(f))) {
          sourceList.push(f);
        }
      };

      this.config.forEach((item) => {
        if (item.values) {
          item.values.forEach((v) => {
            push({ ...item, value: v.value, valueLabel: v.label });
          });
        } else {
          push(item);
        }
      });

      const normalize = (str) => str.toLowerCase();

      const words = normalize(value).split(/\s+/);
      const list = [];
      const match = (str) => {
        if (!str) return 0;
        const label = normalize(str);
        for (let i = 0; i < words.length; i += 1) {
          if (label.indexOf(words[i]) !== 0
          && label.indexOf(` ${words[i]}`) <= 0
          ) {
            return 0;
          }
        }
        return 1;
      };

      sourceList.forEach((item) => {
        if (match(item.label) || match(item.valueLabel || item.value)) {
          list.push(item);
        }
      });

      // const list = this.config
      //   .filter(item =>
      //     item.label.toLowerCase().indexOf(value.toLowerCase()) === 0
      //     || item.label.toLowerCase().indexOf(` ${value.toLowerCase()}`) > 0
      //   );

      $done(list.slice(0, 10));
    },
    clearSuggestions() {
      this.suggestions = [];
    },
    tagsChanged() {
      this.$emit('update', this.filterList);
    },
    commit() {
      console.log('commit');
      this.$emit('commit', this.filterList);
    },
  },
  computed: {
    hasPlaceholder() {
      return (
        this.filterList.length === 0 && !this.inputValue
      );
    },
    filterList() {
      return [].concat(this.filters || []);
    },

  },
  data() {
    return {
      // filterList: [],
      suggestions: [],
      focus: false,
      selectedSuggestionIndex: -1,
      inputValue: '',
    };
  },
  mounted() {
    this.updateInputSize();
  },
};
</script>
<style lang="scss" scoped>

$border-radius: 10px;
$border-width: 1px;
$primary: #fc8e45;

.q-bar-input {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  position: relative;
  text-align: left;
  z-index: 1;
  background-color: #fff;
  display: block;
  border: 1 solid #ccc;
  border-radius: $border-radius;
  padding: 0.4em 0.5em;
  font-weight: normal;
  transition: border-color 0.2 ease;
  box-sizing: border-box;
  &.focus {
    border-color: #ccc;
    .dropdown {
      opacity: 1;
      border: $border-width solid #ccc;
      border-top: $border-width dashed #ccc;
      max-height: 250px;
      transition: max-height 0.2s ease, margin-top 0.2s ease-in;
      margin-top: 0;
      box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    }
  }

  &.suggest {
    border-radius: $border-radius $border-radius 0 0;
  }

  input[name="tagInput"] {
    width: auto;
    border: none;
    display: inline-block;
    background: transparent;
    vertical-align: bottom;
    box-shadow: none;
    padding: 0.2em 0;
    margin-bottom: 0.4em;
    &:focus {
      outline: none;
    }
  }
  input:first-child {
    margin-left: 1em;
  }
}

.tag-sizer {
  top: -1000px;
  position: absolute;
  visibility: hidden;
}

.placeholder, .tag {
  display: inline-block;
  margin-right: 0.4em;
  margin-bottom: 0.4em;
  padding: 0.2em 0.5em;
  vertical-align: middle;
}

.placeholder {
  opacity: 0.6;
  margin-left: -1.5em;
}

.focus .placeholder {
  opacity: 0.3;
}
.dropdown {
  position: absolute;
  width: 100%;
  border: $border-width solid transparent;
  margin-left: -0.6em;
  background: #fff;
  max-height: 0;
  overflow: hidden;
  //display: none;
  display: block;
  transition: opacity 0.2s ease, max-height 0.15s ease, margin-top 0.1s ease-in;
  opacity: 0;
  margin-top: -5px;
}

.options {
  padding: 10px;
}

.option {
  margin: 0.3em 2em 0.3em 0;
  display: inline-block;
}

.tag-suggestions {
  z-index: 1000;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  top: 100%;
  left: 0;
  margin-left: -$border-width;
  border-top: 2px dashed #999;
  background: #fff;
  box-sizing: content-box;
  font-weight: 500;
  border-radius: 0 0 $border-radius $border-radius;
  overflow: hidden;
  box-shadow: 0 5px 10px rgba(black,.1);
  a {
    display: inline-flex;
    text-decoration: none;
    color: #fff;
     padding: 2px;
    margin: 0.5em;
    cursor: pointer;
    transition: all .1s ease;
    border-radius: $border-radius;
    box-shadow: 0 0 0 1px rgba(black, 0);;
    &:hover {
      box-shadow: 0 0 0 2px rgba(black, .2);;
    }
    div {
      display: inline-flex;
      align-items: center;
      border-radius: $border-radius;
      box-shadow: 0 1px 2px rgba(black, .3);
      overflow: hidden;
    }
    span, em {
      padding: 0.2em 0.7em;
    }
    em {
      font-weight: 300;
      font-style: normal;
      color: #333;
      background: rgba(white, .5);
    }

  }
  a.selected {
    box-shadow: 0 0 0 2px rgba(black, .5);;
  }
}
</style>
